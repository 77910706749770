footer {
    background-color: var(--asics-main);
}

footer .wrapper-footer {
    max-width: var(--asics-container-width);
    margin: 0 auto;
    background-color: var(--asics-main);
    padding: 30px 0;
}

footer .footer-v1 {
    background-color: var(--asics-main);
    color: #FFFFFF;
}

.footer-v1-content .links li {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    color: #fff!important;
    margin-bottom: 15px!important;
    cursor: pointer;
}

.footer-v1-content p, .footer-v1-content a {
    color: #FFFFFF !important;
}

footer .footer-v1-content {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.newsletter-container {
    background: transparent !important;
    height: 40px !important;
    margin-bottom: 0 !important;
}

footer .newsletter-container .wrapper {
    top: 0;
}

footer .newsletter-container form {
    display: flex;
}

footer .newsletter-container form .field-newsletter{
    margin-left: 0;
}

footer .newsletter-container form .actions button{
    width: 116px;
    height: 34px;
    background-color: var(--asics-main-bg);
    border-radius: 21px;
    cursor: pointer;
    border: none;
    border-style: none !important;
}
footer .newsletter-container form .actions button span{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
}

footer .newsletter-container button {
    width: 116px !important;
    height: 34px !important;
    background-color: var(--asics-main-bg) !important;
    border-radius: 21px !important;
    cursor: pointer !important;
    border: none !important;
    border-style: none !important;
}

footer .newsletter-title{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 20px;
}

footer .newsletter-container input {
    /* width: 228px !important; */
    height: 34px !important;
    background-color: white !important;
    border-radius: 17px !important;
    font-size: 16px !important;
    /* padding: 10px 16px !important; */
    color: var(--asics-main) !important;
    letter-spacing: 0px !important;
    font-weight: normal !important;
    border: none !important;
}

footer .block-newsletter {
    margin: 0 !important;
}

footer .newsletter-description{
    width: auto;
    padding-bottom: 20px !important;
    font-weight: 700;
}

footer .newsletter-footer {
    width: 357px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-bottom: 1px solid white;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0px;
}

footer .wrapper-footer .footer-v1-content-link h3{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    margin-top: 0;
    margin-bottom: 20px;
}

footer .copyright{
    background-color: white;
    padding: 20px 0;
}
footer .copyright span{
    padding: 0 167px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0px;
    color: var(--asics-main);
}

footer .wrapper-footer p a{
    color: #FFFFFF;
    text-decoration-line: underline;
}

footer .wrapper-footer .mg-mobile .social-icons{
    width: 30px;
    height: 30px;
    border: none;
    margin-right: 15px;
}

@media only screen and (max-width : 1200px) {
    footer .wrapper-footer{
        padding-left: 20px;
        padding-right: 20px;
    }
    footer .copyright span{
        padding: 0 20px;
    }
}
@media only screen and (max-width : 992px) {
    footer .wrapper-footer .footer-v1-content{
        display: grid;
        grid-template-columns: auto;
    }
    footer .wrapper-footer .columns-1{
        grid-column: 1/5;
    }
    footer .wrapper-footer .newsletter-footer{
        width: auto;
    }
    footer .wrapper-footer .footer-v1-content-link{
        margin-top: 20px;
    }
}
@media only screen and (max-width : 767px) {
    footer .wrapper-footer .columns-1{
        grid-column: 1/3;
    }
    footer .newsletter-container .field-newsletter{
        margin-bottom: 0;
    }
    footer .newsletter-container input{
        width: 100%;
        min-width: 0 !important;
    }
    footer .newsletter-container form{
        flex-direction: row !important;
    }
    footer .newsletter-container form .actions{
        margin-top: 0;
    }
}
@media only screen and (max-width : 375px) {
    footer .newsletter-container form{
        flex-direction: column !important;
    }
    footer .newsletter-container form .actions{
        margin-left: 0;
        margin-top: 13px;
    }
    footer .newsletter-description {
        margin-bottom: 20px;
    }
    footer .newsletter-footer {
        margin-top: 20px;
    }
}

/* footer .wrapper-footer{
    max-width: var(--asics-container-width);
    margin: 0 auto;
    background-color: transparent;
    padding: 30px 0;
}
footer .wrapper-footer .mgz-container{
    display: flex;
    justify-content: space-between;
}

.footer-v1-content .links li a {
    color: #FFFFFF !important;
}

.footer-v1-content .footer-title {
    color: #FFFFFF !important;
}

.footer-v1-content .newsletter-container{
    background-color: var(--asics-main) !important;
    height: 20px !important;
}

footer .newsletter-title{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 20px;
}
footer .newsletter-description{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 20px;
}
footer .newsletter-container form{
    display: flex;
}
footer .newsletter-container form .actions{
    margin-left: 13px;
}
footer .newsletter-container form .actions button{
    width: 116px;
    height: 34px;
    background-color: var(--asics-main-bg);
    border-radius: 21px;
    cursor: pointer;
    border: none;
    border-style: none !important;
}
footer .newsletter-container form .actions button span{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
}
footer .newsletter-container input{
    width: 228px;
    height: 34px;
    background-color: white;
    border-radius: 17px;
    font-size: 16px;
    padding: 10px 16px;
    color: var(--asics-main);
    letter-spacing: 0px;
    font-weight: normal;
    border: none;
}
footer .wrapper-footer .footer__service{
    width: 357px;
    margin-top: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid white;
}
footer .wrapper-footer .footer__service p{
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
}
footer .wrapper-footer .footer__service p a{
    color: #FFFFFF;
    text-decoration-line: underline;
}

footer .wrapper-footer .mg-mobile .social-icons{
    width: 30px;
    height: 30px;
    border: none;
    margin-right: 15px;
}
footer .wrapper-footer .mg-mobile .social-icons:hover{
    background-color: transparent !important;
}
footer .wrapper-footer .mg-mobile .social-icons img{
    width: 30px;
    height: 30px;
}
footer .wrapper-footer .mg-mobile h4{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 20px 0;
}
footer .wrapper-footer .footer__content-link h3{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    margin-top: 0;
    margin-bottom: 20px;
}
footer .wrapper-footer .footer__content-link p a{
    color: #FFFFFF;
}
footer .wrapper-footer .footer__content-link p a:hover{
    color: #FFFFFF;
}
footer .wrapper-footer .footer__content-link p{
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    margin-bottom: 15px !important;
    cursor: pointer;
}
footer .wrapper-footer .footer__content-link p:last-child{
    margin-bottom: 0 !important;
}
footer .copyright{
    background-color: white;
    padding: 20px 0;
    text-align: left;
}
footer .copyright span{
    padding: 0 167px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0px;
    color: var(--asics-main);
}
@media only screen and (max-width : 1200px) {
    footer .wrapper-footer{
        padding-left: 20px;
        padding-right: 20px;
    }
    footer .copyright span{
        padding: 0 20px;
    }
}
@media only screen and (max-width : 992px) {
    footer .wrapper-footer .mgz-container{
        display: grid;
        grid-template-columns: auto;
    }
    footer .wrapper-footer .mgz-container .columns-1{
        grid-column: 1/5;
    }
    footer .wrapper-footer .footer__service{
        width: auto;
    }
    footer .wrapper-footer .footer__content-link{
        margin-top: 20px;
    }
}
@media only screen and (max-width : 768px) {
    footer .wrapper-footer .mgz-container .columns-1{
        grid-column: 1/3;
    }
    footer .newsletter-container .mgz-newsletter-field input{
        width: 100%;
    }
}
@media only screen and (max-width : 375px) {
    footer .newsletter-container form{
        flex-direction: column;
    }
    footer .newsletter-container form .actions{
        margin-left: 0;
        margin-top: 13px;
    }
} */