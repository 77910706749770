.tabs-about-us{
    display: flex;
    flex-direction: column;
}

.tabs-about-us a{
    padding: 15px 0;
    border-bottom: 1px solid var(--asics-main-bg);
}

.tabs-about-us a span{
    font-size: 14px;
    text-transform: uppercase;
    color: var(--asics-main) !important;
    font-weight: bold;
    margin-left: 0;
    display: inline-block;
    width: auto;
    line-height: 0;
}
.tabs__about-us a span.title__about-us::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__cookies-policy a span.title__cookies-policy::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__disclaimer a span.title__disclaimer::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__privacy-policy a span.title__privacy-policy::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__contact-us a span.title__contact-us::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__terms-conditions a span.title__terms-conditions::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__returns-policy a span.title__returns-policy::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__shipping-information a span.title__shipping-information::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__faq a span.title__faq::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
@media screen and (max-width: 788px) {
    .cms-container h4.title{
        display: none;
    }
    .tabs-about-us a{
        padding: 14px 0;
    }

    .tabs-about-us a span{
        font-size: 12px
    }
}