/* General Styling */
main#maincontent .cms-block-category {
    padding-top: 12px;
}
main#maincontent .cms-block-category h1,
main#maincontent .cms-block-category h2,
main#maincontent .cms-block-category h3 {
    margin: 0;
    font-weight: 400;
}
main#maincontent .cms-block-category .magezon-builder img {
    max-width: 100%;
}
main#maincontent .cms-block-category .mgz-element-single_image {
    line-height: 0;
}
main#maincontent .cms-block-category .mgz-button .mgz-btn {
    display: inline-block;
    font-size: 18px;
    padding: 12px 64px;
    color: white !important;
    background: var(--asics-main);
}
/* End of General Styling */



main#maincontent .cms-block-category .magezon-builder .img-rounded {
    border-radius: 36px;
    overflow: hidden;
}
main#maincontent .cms-block-category .magezon-builder .row-cols-3 > div > div,
main#maincontent .cms-block-category .magezon-builder .row-cols-4 > div > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

main#maincontent .cms-block-category .magezon-builder .row-cols-3 > div > div > div {
    width: calc((100% / 3));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-3.row-gap-xs > div > div > div {
    width: calc((100% / 3) - var(--template-gap-xs));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-3.row-gap-sm > div > div > div {
    width: calc((100% / 3) - var(--template-gap-sm));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-3.row-gap-md > div > div > div {
    width: calc((100% / 3) - var(--template-gap-md));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-3.row-gap-lg > div > div > div {
    width: calc((100% / 3) - var(--template-gap-lg));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-4 > div > div > div {
    width: 25%;
}
main#maincontent .cms-block-category .magezon-builder .row-cols-4.row-gap-xs > div > div > div {
    width: calc(25% - var(--template-gap-xs));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-4.row-gap-sm > div > div > div {
    width: calc(25% - var(--template-gap-sm));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-4.row-gap-md > div > div > div {
    width: calc(25% - var(--template-gap-md));
}
main#maincontent .cms-block-category .magezon-builder .row-cols-4.row-gap-lg > div > div > div {
    width: calc(25% - var(--template-gap-lg));
}

main#maincontent .cms-block-category .magezon-builder .row-cols-3 img,
main#maincontent .cms-block-category .magezon-builder .row-cols-4 img {
    width: 100%;
}
main#maincontent .cms-block-category .magezon-builder .row-gap-xs > div > div {
    gap: var(--template-gap-xs);
}
main#maincontent .cms-block-category .magezon-builder .row-gap-sm > div > div {
    gap: var(--template-gap-sm);
}
main#maincontent .cms-block-category .magezon-builder .row-gap-md > div > div {
    gap: var(--template-gap-md);
}
main#maincontent .cms-block-category .magezon-builder .row-gap-lg > div > div {
    gap: var(--template-gap-lg);
}



/* Small Dekstop Screen*/
@media only screen and (max-width : 1023px) {
    main#maincontent .cms-block-category .magezon-builder .desktop-small\:row-cols-1 > div > div > div {
        width: 100% !important;
    }
    main#maincontent .cms-block-category .magezon-builder .desktop-small\:row-cols-2 > div > div > div {
        width: 50%;
    }
    main#maincontent .cms-block-category .magezon-builder .desktop-small\:row-cols-2.row-gap-xs > div > div > div {
        width: calc(50% - var(--template-gap-xs));
    }
    main#maincontent .cms-block-category .magezon-builder .desktop-small\:row-cols-2.row-gap-sm > div > div > div {
        width: calc(50% - var(--template-gap-sm));
    }
    main#maincontent .cms-block-category .magezon-builder .desktop-small\:row-cols-2.row-gap-md > div > div > div {
        width: calc(50% - var(--template-gap-md));
    }
    main#maincontent .cms-block-category .magezon-builder .desktop-small\:row-cols-2.row-gap-lg > div > div > div {
        width: calc(50% - var(--template-gap-lg));
    }
}

/* Tablet Screen*/
@media only screen and (max-width : 768px) {
    main#maincontent .cms-block-category .magezon-builder .tablet\:row-cols-1 > div > div > div {
        width: 100% !important;
    }
    main#maincontent .cms-block-category .magezon-builder .tablet\:row-cols-2 > div > div > div {
        width: 50%;
    }
    main#maincontent .cms-block-category .magezon-builder .tablet\:row-cols-2.row-gap-xs > div > div > div {
        width: calc(50% - var(--template-gap-xs));
    }
    main#maincontent .cms-block-category .magezon-builder .tablet\:row-cols-2.row-gap-sm > div > div > div {
        width: calc(50% - var(--template-gap-sm));
    }
    main#maincontent .cms-block-category .magezon-builder .tablet\:row-cols-2.row-gap-md > div > div > div {
        width: calc(50% - var(--template-gap-md));
    }
    main#maincontent .cms-block-category .magezon-builder .tablet\:row-cols-2.row-gap-lg > div > div > div {
        width: calc(50% - var(--template-gap-lg));
    }
}

/* Mobile Screen */
@media only screen and (max-width : 480px) {
    /* General Styling */
        main#maincontent .cms-block-category .magezon-builder h3,
        main#maincontent .cms-block-category .magezon-builder h3 span {
            font-size: 18px !important;
        }
        main#maincontent .cms-block-category .magezon-builder h2,
        main#maincontent .cms-block-category .magezon-builder h2 span {
            font-size: 32px !important;
        }
    /* End of General Styling */

    main#maincontent .cms-block-category .magezon-builder .mobile\:row-cols-1 > div > div > div {
        width: 100% !important;
    }
    main#maincontent .cms-block-category .magezon-builder .mobile\:row-cols-2 > div > div > div {
        width: 50%;
    }
    main#maincontent .cms-block-category .magezon-builder .mobile\:row-cols-2.row-gap-xs > div > div > div {
        width: calc(50% - var(--template-gap-xs));
    }
    main#maincontent .cms-block-category .magezon-builder .mobile\:row-cols-2.row-gap-sm > div > div > div {
        width: calc(50% - var(--template-gap-sm));
    }
    main#maincontent .cms-block-category .magezon-builder .mobile\:row-cols-2.row-gap-md > div > div > div {
        width: calc(50% - var(--template-gap-md));
    }
    main#maincontent .cms-block-category .magezon-builder .mobile\:row-cols-2.row-gap-lg > div > div > div {
        width: calc(50% - var(--template-gap-lg));
    }
}