@font-face {
    font-family: 'Asics';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../theme/fonts/asics/ASICSFont3.0-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Asics';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../theme/fonts/asics/ASICSFont3.0-Bold.woff2') format('woff2');
}
