main#maincontent .cms-block-category .inner-content.mgz-container .img-rounded img {
    border-radius: 30px;
}

main#maincontent .cms-block-category .inner-content.mgz-container .banner-text-right {
    width: 50%;
}

main#maincontent .cms-block-category .the-team .inner-content.mgz-container {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

main#maincontent .cms-block-category .the-team .inner-content.mgz-container .img-small-rounded img {
    border-radius: 20px;
}

main#maincontent .cms-block-category .row-rounded .mgz-element-inner {
    border-radius: 30px;
}

main#maincontent .cms-block-category .row-flex .inner-content.mgz-container {
    display: flex;
    gap: 20px;
}
main#maincontent .cms-block-category .row-flex .inner-content.mgz-container .first-row {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
}

main#maincontent .cms-block-category .row-flex .inner-content.mgz-container  .mgz-element-button.btn-circle {
    width: 40px;
    height: 40px;
    background: #001E62;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}
main#maincontent .cms-block-category .row-flex .inner-content.mgz-container  .mgz-element-button.btn-circle:after{
    content: "\2197";
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}
main#maincontent .cms-block-category .row-flex .inner-content.mgz-container  .mgz-element-button.btn-circle .mgz-link.mgz-btn {
    padding: 0;
}
main#maincontent .cms-block-category .row-flex .inner-content.mgz-container .text-limit > .mgz-element-inner {
    padding: 0 12px
}
main#maincontent .cms-block-category .row-flex .inner-content.mgz-container .text-limit.limit > .mgz-element-inner {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
main#maincontent .cms-block-category .row-flex .inner-content.mgz-container #btn-see-more {
    cursor: pointer;
    padding: 0 12px
}

@media only screen and (max-width : 768px) {
    main#maincontent .cms-block-category .inner-content.mgz-container .banner-text-right {
        width: 100%;
    }
    main#maincontent .cms-block-category .inner-content.mgz-container .banner-text-right p {
        text-align: left !important;
    }
    main#maincontent .cms-block-category .the-team .inner-content.mgz-container {
        flex-wrap: wrap;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    main#maincontent .cms-block-category .the-team .inner-content.mgz-container  .mgz-element-column {
        flex-basis: 50%;
    }
    main#maincontent .cms-block-category .row-flex .inner-content.mgz-container {
        flex-wrap: wrap;
    }
    main#maincontent .cms-block-category .row-flex .inner-content.mgz-container .first-row {
        flex-basis: 100%;
        padding: 0 12px;
    }
}   