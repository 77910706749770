@import './mediaquery.css';
@import './fonts.css';
@import './asics-icon.css';
@import 'asics-styles/variables.css';
@import 'asics-styles/cms-page.css';
@import 'asics-styles/cms-block-category.css';
@import 'asics-styles/mlp.css';
@import 'asics-styles/header.css';
@import 'asics-styles/footer.css';
@import 'asics-styles/homepage.css';
@import 'asics-styles/about-us.css';
@import 'asics-styles/size-guide.css';
@import 'asics-styles/checkout.css';
@import 'asics-styles/pdp.css';
main,
body,
.MuiButton-label,
.MuiTypography-root,
.MuiTab-root,
.MuiFormLabel-root {
    font-family: var(--asics-font) !important;
}