/* header general */
header > div > .slider-container + #arrow-left,
header > div > .slider-container ~ #arrow-right {
    display: none;
}
header > div > .slider-container #carousel + button{
    top: 50%;
    transform: translate(0, -50%);
}
#header.header-small > #header {
    top: 0;
}
#header.header-small > #header .header-top{
    height: 0;
    padding: 0;
    border-bottom: 0 transparent;
    overflow: hidden;
}
.header-top .header-top-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.header-top .header-top-inner .explore-our-brand .inner-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.header-top .header-top-inner .explore-our-brand .inner-content > div {
    padding: 0 15px;
}
.header-top .header-top-inner .explore-our-brand .inner-content > div:not(:first-child) {
    border-left: 1px solid var(--asics-main-bg);
}
.header-top .header-top-inner .explore-our-brand .inner-content > div:first-child {
    padding-left: 0;
}
.header-top .header-top-inner .explore-our-brand .inner-content .explore-image a {
    display: block;
    line-height: 0;
}
.header-top .header-top-inner .explore-our-brand .inner-content .explore-image a img {
    height: 20px;
}
.header-top .header-top-inner .explore-our-brand .inner-content .explore-text {
    font-size: 13px;
}
.header-top .header-top-inner .menu-top-right .MuiButton-root {
    text-transform: capitalize;
    margin-top: -3px;
}
.header-top .header-top-inner .menu-top-right .MuiButton-root > .MuiButton-label {
    font-size: 0;
}
.header-top .header-top-inner .menu-top-right .MuiButton-root > .MuiButton-label strong {
    font-size: 13px;
    font-weight: 400;
}
.header-top .header-top-inner .menu-top-right .MuiButton-root:hover {
    background-color: transparent !important;
}
.header-middle__right .shopping-bag .MuiBadge-root:after {
    content: '\e900';
    font-family: 'asics-icon';
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: normal;
} 
.header-middle__right .shopping-bag .MuiBadge-root {
    text-align: center;
}
.header-middle__right .shopping-bag .MuiBadge-root .MuiBadge-badge {
    background-color: var(--asics-red) !important;
}
.header-middle__right .shopping-bag .MuiBadge-root .MuiSvgIcon-root {
    display: none;
}
/* end header general */

/* searchbar */
header .search-header fieldset {
    border: 1px solid var(--asics-main);
    border-radius: 20px;
}
header .search-icon > button {
    padding: 0;
    margin-left: 0;
    color: var(--asics-main);
}
header .search-icon > button .MuiIconButton-label {
    text-align: center;
}
header .search-icon > button .MuiIconButton-label:after {
    content: '\e904';
    font-family: 'asics-icon';
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: normal;
}
header .search-icon > button .MuiIconButton-label .MuiSvgIcon-root {
    display: none;
}
header .search-icon > button.Mui-disabled {
    color: var(--asics-main) !important;
}
header .search-header .MuiInputLabel-formControl {
    font-size: 11px;
    transform: translate(14px, 12px);
}
header .search-header .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -3px);
}
header div.search-header .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 40px;
}
header .search-header .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 4px;
    height: 34px;
}
header .search-header .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0 4px 0 7px;
}
header .search-header input {
    font-size: 13px;
    height: 25px;
}
/* end searchbar */

@media (max-width: 1023px) {
    body header >.MuiToolbar-root {
        margin-top: 35px;
    }
    body header >.MuiToolbar-root > .MuiAutocomplete-root > .MuiFormControl-root {
        margin: 0;
    }  
    body header .search-header .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        height: 45px;
    } 
    body header .search-header .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 5px 4px 5px 7px;
    }
    body header .search-header fieldset {
        border-radius: 23px;
    }
    body header .search-header .MuiInputLabel-formControl {
        font-size: 1rem;
        transform: translate(14px, 14px);
    }
    body header > .MuiToolbar-root > .MuiIconButton-edgeStart {
        color: var(--asics-main) !important;
        background-color: #FFFFFF;
    }
    body header .search-header + .MuiIconButton-root {
        padding: 8px 12px;
        position: absolute;
        right: 4%;
    }
}