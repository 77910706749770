:root {
    --asics-font: 'Asics', sans-serif;
    --asics-container-width: 1184px;

    --asics-main: #001E62;
    --asics-main-bg: #B6B8DC;
    --asics-grey: #d6d6d6;
    --asics-red: #E04F39;

    --template-gap-xs: 1px;
    --template-gap-sm: 8px;
    --template-gap-md: 12px;
    --template-gap-lg: 20px;
}