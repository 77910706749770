.size-guide-tabs {
    display: flex;
    flex-direction: column;
}

.size-guide-tabs a.size-guide-anchor {
    padding: 15px 0;
    border-bottom: 1px solid var(--asics-main-bg);
}

.size-guide-tabs a.size-guide-anchor > span {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--asics-main) !important;
    font-weight: 700;
    margin-left: 0;
    display: inline-block;
    width: auto;
    line-height: 0;
}

.size-guide-tabs a.size-guide-anchor.active > span::after {
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}

/* ===================================================================== */

.tabs-shoe-size-guide{
    display: flex;
    flex-direction: column;
}

.tabs-shoe-size-guide a{
    padding: 15px 0;
    border-bottom: 1px solid var(--asics-main-bg);
}

.tabs-shoe-size-guide a span{
    font-size: 14px;
    text-transform: uppercase;
    color: var(--asics-main) !important;
    font-weight: bold;
    margin-left: 0;
    display: inline-block;
    width: auto;
    line-height: 0;
}
.tabs__shoe-size-guide a span.title__shoe-size-guide::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__shoe-width-guide a span.title__shoe-width-guide::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
}
.tabs__clothing-size-guide a span.title__clothing-size-guide::after{
    border-radius: 6px 6px 0px 0px;
    content: '';
    display: block;
    height: 5px;
    background-color: #B6B8DC;
    width: 100%;
    position: relative;
    top: 20px;
 }

@media screen and (max-width: 768px) {
    .tabs-shoe-size-guide a{
        padding: 14px 0;
    }

    .tabs-shoe-size-guide a span{
        font-size: 12px
    }
    .tabs__shoe-size-guide table{
        width: 100% !important;
        display: block;
        overflow: auto;
        height: auto !important;
        border: none;
    }
    .tabs__shoe-width-guide table{
        width: 100% !important;
        display: block;
        overflow: auto;
        height: auto !important;
        border: none;
    }
    .tabs__clothing-size-guide table{
        width: 100% !important;
        display: block;
        overflow: auto;
        height: auto !important;
        border: none;
    }
}
