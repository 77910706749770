/* Asics Icon */
@font-face {
  font-family: 'asics-icon';
  font-style: normal;
  font-weight: normal;
  src: local(''),
       url('../theme/fonts/asics-icon/icomoon.woff2') format('woff2');
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'asics-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-asics-cart:before {
  content: "\e900";
  color: #001e62;
}
.icon-asics-loop:before {
  content: "\e904";
  color: #001e62;
}
.icon-asics-user:before {
  content: "\e901";
  color: #001e62;
}
.icon-asics-location:before {
  content: "\e902";
  color: #001e62;
}
.icon-asics-help:before {
  content: "\e903";
  color: #001e62;
}
