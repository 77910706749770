/* General Styling */
main#maincontent .cms-container > div > div > div > div.mgz-element {
    padding-left: 0;
    padding-right: 0;
    padding: 0;
}
main#maincontent .cms-container .magezon-image picture {
    display: block;
    line-height: 0;
}
main#maincontent .cms-container .mgz-button button {
    font-size: 16px;
    padding: 8px 64px;
}
main#maincontent .cms-container h3,
main#maincontent .cms-container h2 {
    margin: 0;
}
main#maincontent .cms-container .magezon-empty {
    width: 100%;
}

@media only screen and (max-width : 425px) {
    main#maincontent .cms-container h3,
    main#maincontent .cms-container h3 span {
        font-size: 18px !important;
    }
    main#maincontent .cms-container h2,
    main#maincontent .cms-container h2 span {
        font-size: 32px !important;
    }
}
/* End of General Styling */


/* Utility Class */
main#maincontent .cms-container .img-rounded > a > div,
main#maincontent .cms-container .slider-rounded .slick-slider .slick-list {
    border-radius: 36px;
    overflow: hidden;
}
main#maincontent .cms-container .img-rounded > a > div > picture {
    display: block;
    line-height: 0;
}
main#maincontent .cms-container .clear-padding > div {
    padding: 0;
}
main#maincontent .cms-container .border-white {
    border-left: solid white 1px;
    border-right: solid white 1px;
}
main#maincontent .cms-container .btn-center,
main#maincontent .cms-container .img-center {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
main#maincontent .cms-container .img-cols-4 > div > .mgz-element {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
main#maincontent .cms-container .img-cols-4 > div {
    align-self: center;
    margin: auto;
}
main#maincontent .cms-container .row-gap-md {
    gap: 8px;
}
main#maincontent .cms-container .row-gap-md > div {
    flex: 1;
}
main#maincontent .cms-container .column-rounded {
    line-height: 0;
    border-radius: 36px;
    overflow: hidden;
}
main#maincontent .cms-container .mgz-element:has(> .row-rounded) {
    border-radius: 36px;
    overflow: hidden;
}
main#maincontent .cms-container .mgz-element:has(> .row-fullwidth) {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
}
main#maincontent .cms-container .row-fullwidth {
    max-width: var(--asics-container-width);
    margin: auto;
}
main#maincontent .cms-container .space-between-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
main#maincontent .cms-container .btn-right {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
}
main#maincontent .cms-container .btn-circle button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;
    min-width: 0;
}
main#maincontent .cms-container .btn-arrow button {
    position: relative;
}
main#maincontent .cms-container .btn-arrow button::after {
    content: "\2197";
    font-size: 18px;
    position: absolute;
}
main#maincontent .cms-container .magezon-icon-inner > i {
    color: white;
}
main#maincontent .cms-container .tablet-text-center {
    text-align: center;
}
main#maincontent .cms-container .img-rounded > a > div,
main#maincontent .cms-container .slider-rounded .slick-slider .slick-list {
    border-radius: 36px;
    overflow: hidden;
}
main#maincontent .cms-container .img-small-rounded {
    border-radius: 24px;
    overflow: hidden;
}
main#maincontent .cms-container .relative {
    position: relative;
}
main#maincontent .cms-container .desktop-absolute-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
main#maincontent .cms-container .desktop-absolute-top {
    position: absolute;
    top: 40px;
}
main#maincontent .cms-container .img-prevent-fullwidth img {
    width: fit-content !important;
}

/* Section Countdown */
main#maincontent .section-countdown-row {
    position: relative;
}
main#maincontent .section-countdown-column {
    width: 45%;
    position: absolute;
}
main#maincontent .section-countdown-column.top-left {
    top: 0;
    left: 0;
}
main#maincontent .section-countdown-column.top-right {
    top: 0;
    right: 0;
}
main#maincontent .section-countdown-column.bottom-left {
    bottom: 0;
    left: 0;
}
main#maincontent .section-countdown-column.bottom-right {
    bottom: 0;
    right: 0;
}
main#maincontent .section-countdown-column.middle-left {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}
main#maincontent .section-countdown-column.middle-right {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}
main#maincontent .section-countdown-column.middle-center {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}
main#maincontent .section-countdown-row .mgz-element,
main#maincontent .section-countdown-column .mgz-element {
    padding: 0 !important;
}
main#maincontent .section-countdown-column .mgz-countdown {
    margin: 0;
    margin: auto;
    padding: 20px 0;
}
main#maincontent .section-countdown-column.with-bg .mgz-countdown {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
}
main#maincontent .section-countdown-column .mgz-countdown-number {
    background: #fff;
}
main#maincontent .section-countdown-column .mgz-countdown-item {
    margin: 0 10px;
}
main#maincontent .section-countdown-column .mgz-countdown-unit-label,
main#maincontent .section-countdown-column .mgz-countdown-heading-wrapper {
    margin-bottom: 10px;
}
main#maincontent .section-countdown-column .mgz-countdown-unit-label {
    margin: 0;
}
main#maincontent .section-countdown-column .mgz-countdown-link {
    text-align: center;
}

@media only screen and (max-width : 1023px) {
    main#maincontent .cms-container p {
        margin: 0;
    }
    main#maincontent .cms-container .img-cols-4 {
        display: flex;
        justify-content: space-evenly;
    }
    main#maincontent .cms-container .img-cols-4 > div {
        flex-basis: 25%;
    }
    main#maincontent .cms-container .img-cols-4 > div > .mgz-element {
        width: 90%;
    }
    main#maincontent .cms-container .img-cols-3 {
        display: block;
    }
    main#maincontent .cms-container .img-cols-3 .magezon-image > a > div,
    main#maincontent .cms-container .img-cols-3 .magezon-image img { 
        width: 100% !important;
    }
    main#maincontent .cms-container .img-responsive-small img {
        width: 50vw !important;
    }

    /*Section Countdown*/
    main#maincontent .section-countdown-column.middle-center {
        right: 7%;
        left: unset;
        transform: translateY(-50%);
    }

    main#maincontent .section-countdown-column .mgz-countdown {
        scale: 0.8;
        margin-top: 10px;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1279px) {
    main#maincontent .section-countdown-column .mgz-countdown-unit-number {
        font-size: 18px;
    }
}


@media only screen and (max-width : 900px) {
    main#maincontent .section-countdown-column .mgz-countdown {
        scale: 0.7;
        margin-top: 10px;
    }
}

@media only screen and (max-width : 768px) {
    main#maincontent .cms-container .tablet-margin-top {
        margin-top: 16px;
    }

    /*Section Countdown*/
    main#maincontent .section-countdown-column .mgz-countdown-item {
        width: 40px;
    }
    main#maincontent .section-countdown-column .mgz-countdown {
        scale: 1;
        margin-top: 0;
    }
    main#maincontent .section-countdown-column.with-bg .mgz-countdown {
        background-color: unset;
        backdrop-filter: unset
    }
    main#maincontent .section-countdown-column {
        width: 100%;
        position: inherit;
    }
    main#maincontent .section-countdown-column .mgz-countdown-unit-number {
        font-size: 16px;
    }
    main#maincontent .section-countdown-column .mgz-countdown-unit-label {
        font-size: 10px;
    }
    main#maincontent .section-countdown-column .mgz-countdown-link span {
        font-size: 16px !important;
    }
    main#maincontent .section-countdown-column.top-left,
    main#maincontent .section-countdown-column.top-right,
    main#maincontent .section-countdown-column.bottom-left,
    main#maincontent .section-countdown-column.bottom-right,
    main#maincontent .section-countdown-column.middle-left,
    main#maincontent .section-countdown-column.middle-right,
    main#maincontent .section-countdown-column.middle-center
     {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: unset;
    }
}

@media only screen and (max-width : 480px) {
    main#maincontent .cms-container .img-cols-4 > div {
        flex-basis: 50%;
    }
    main#maincontent .cms-container .mobile-cols-3 {
        justify-content: center;
    }
    main#maincontent .cms-container .mobile-cols-3 > div {
        flex-basis: 30%;
        max-width: none;
        flex-grow: 0;
    }
    main#maincontent .cms-container .row-gap-md {
        gap: 4px;
        padding: 2px 0;
    }
    main#maincontent .cms-container .desktop-absolute-center,
    main#maincontent .cms-container .desktop-absolute-top {
        position: static;
    }
    main#maincontent .cms-container .row-gap-md.mobile-cols-2 > div {
        flex-basis: calc(50% - 4px);
        max-width: 100%;
    }
}

/* End of Utility Class */