body {
    overflow-x: hidden;
}
main.main-app-sticky-homepage {
    width: 100% !important;
    max-width: 1366px !important;
    margin: 40px auto 0 auto !important;
}
main.main-app-sticky-homepage main.main-app{
    width: 100% !important;
    max-width: 1366px !important;
    margin: 0 auto;
}
main.main-app-sticky-homepage main > .MuiBackdrop-root + .mgz-element {
    padding-top: 0 !important;
}
main.main-app-sticky-homepage main > .mgz-element .magezon-image div {
    width: 100% !important;
}
main.main-app-sticky-homepage main > .mgz-element .magezon-image picture,
main.main-app-sticky-homepage main > .mgz-element .magezon-image picture img {
    width: 100%;
}
main.main-app-sticky-homepage main .mgz-element h2.MuiTypography-root {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 35px;
}
main.main-app-sticky-homepage > div {
    overflow-x: visible;
}
main.main-app-sticky-homepage main {
    margin-top: 120px !important;
    font-size: 0;
}
main.main-app-sticky-homepage #home-banner {
    position: relative;
    width: 100%;
}
main.main-app-sticky-homepage #home-banner .arrow {
    background-color: transparent;
    font-size: 1.9rem;
    transition: opacity 0.3s ease;
}
main.main-app-sticky-homepage #home-banner .arrow svg {
    display: none;
}
main.main-app-sticky-homepage #home-banner .arrow:after {
    content: '';
    width: 20px;
    height: 20px;
    border-left: 2px solid var(--asics-main);
    border-bottom: 2px solid var(--asics-main);
}
main.main-app-sticky-homepage #home-banner .arrow:hover {
    color: var(--asics-main);
    opacity: 0.7;
}
main.main-app-sticky-homepage #home-banner .arrow-left {
    left: 5%;
}
main.main-app-sticky-homepage #home-banner .arrow-left:after {
    transform: rotate(45deg);
}
main.main-app-sticky-homepage #home-banner .arrow-right {
    right: 5%;
}
main.main-app-sticky-homepage #home-banner .arrow-right:after {
    transform: rotate(-135deg);
}
main.main-app-sticky-homepage #home-banner .slick-slider .slick-slide picture {
    width: 100%;
}
main.main-app-sticky-homepage .mgz-product-slider .slick-slider .slick-list {
    height: auto !important;
    margin-left: -24px;
    margin-right: -24px;
}
main.main-app-sticky-homepage .mgz-product-slider .slick-slider .slick-list .slick-slide {
    padding-left: 24px;
    padding-right: 24px;
}
main.main-app-sticky-homepage .mgz-product-slider .slick-slider .slick-list .mgz-single-product-card:hover,
main.main-app-sticky-homepage .mgz-single-product-card:hover {
    border: 0 transparent;
}
main.main-app-sticky-homepage .mgz-product-slider .slick-slider .slick-list .mgz-single-product-card .MuiGrid-item > h4 {
    font-size: 14px;
    font-weight: 700;
    margin: 30px 0 0;
}

/* SPECIFIC SECTION */
main.main-app-sticky-homepage .section {
    padding: 30px 0;
    width: 100%;
    margin: auto;
}
main.main-app-sticky-homepage .section .MuiButton-root {
    height: 41px;
    border-radius: 21px;
}
main.main-app-sticky-homepage .section .MuiButton-root .MuiButton-label {
    font-weight: 700;
    text-transform: none;
    font-size: 14px;
}
main.main-app-sticky-homepage .section picture{
    line-height: 0;
    display: block;
}
main.main-app-sticky-homepage .section .mgz-column {
    line-height: normal;
}
main.main-app-sticky-homepage .section .mgz-column > .mgz-element {
    padding: 0;
}
/* Section Main Slider */
main.main-app-sticky-homepage .section.section-main-slider {
    padding-top: 0;
    padding-bottom: 60px;
}
main.main-app-sticky-homepage .section.section-main-slider > .column-container {
    line-height: 0;
    font-size: 0;
}
main.main-app-sticky-homepage .section-main-slider .magezon-widget {
    margin-bottom: 0;
}
main.main-app-sticky-homepage .section-main-slider #home-banner .dots {
    bottom: -30px;
    transform: translate(-50%, 0);
}
main.main-app-sticky-homepage .section-main-slider #home-banner .dotItem {
    width: 10px;
    height: 10px;
    border: 1px solid var(--asics-main);
    border-radius: 50%;
    margin: 0 6px;
    cursor: pointer;
}
main.main-app-sticky-homepage .section-main-slider #home-banner .dotActive {
    background-color: var(--asics-main);
}

/* Section Shop New Arrivals */
main.main-app-sticky-homepage .section-shop-new-arrivals {
    max-width: 1184px;
}
main.main-app-sticky-homepage .section-shop-new-arrivals .new-product-slider .mgz-product-heading {
    margin: 0;
    padding: 0;
}
main.main-app-sticky-homepage .section-shop-new-arrivals .new-product-slider .mgz-single-product-card {
    padding-bottom: 0;
    padding-top: 0;
}
main.main-app-sticky-homepage .section-shop-new-arrivals .new-product-slider .mgz-product-slider {
    margin-bottom: 0;
}

/* Section Banner Full */
main.main-app-sticky-homepage .section-single-banner-full {
    width: 100%;
    max-width: none !important;
    position: relative;
}
main.main-app-sticky-homepage .section-single-banner-full > .mgz-column {
    position: relative;
}
main.main-app-sticky-homepage .section-single-banner-full > .mgz-column > .mgz-element:nth-of-type(2) {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text {
    width: 100%;
    max-width: var(--asics-container-width);
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text > .mgz-column {
    width: 100%;
    max-width: 650px;
    flex-basis: auto;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text .top .magezone-html {
    font-size: 50px;
    text-align: right;
    margin-bottom: 8px;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text .top .magezone-html sup {
    font-size: 25px;
    font-weight: 700;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text .sub {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text .sub .magezone-html {
    max-width: 500px;
    font-size: 25px;
    text-align: right;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text .mgz-button div > div {
    display: flex;
    justify-content: flex-end;
}
main.main-app-sticky-homepage .section-single-banner-full .desc-text .MuiButton-root {
    width: 163px;
}

/* Section New Collection */
main.main-app-sticky-homepage .section-new-collection {
    max-width: 1184px;
}
main.main-app-sticky-homepage .section-new-collection .col-img {
    position: relative;
}
main.main-app-sticky-homepage .section-new-collection .col-img > .mgz-element:nth-of-type(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;
}
main.main-app-sticky-homepage .section-new-collection .col-img .magezone-heading > h2 {
    font-size: 31px;
    margin-bottom: 25px;
}
main.main-app-sticky-homepage .section-new-collection .col-img .mgz-button > div > div {
    display: flex;
    justify-content: center;
}
main.main-app-sticky-homepage .section-new-collection .col-img .mgz-button button {
    width: 149px;
}

/* Section Monthly Highlights */
main.main-app-sticky-homepage .section-monthly-highlights > .mgz-column > .mgz-element:nth-of-type(2) {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 -1px;
}
main.main-app-sticky-homepage .section-monthly-highlights .magezon-image {
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
}

/* Section Meet Sport Style */
main.main-app-sticky-homepage .section-meet-sport-style {
    max-width: 1184px
}
main.main-app-sticky-homepage .section-meet-sport-style .image-big {
    position: relative;
}
main.main-app-sticky-homepage .section-meet-sport-style .image-big > .mgz-element:nth-of-type(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 60px 25px;
}
main.main-app-sticky-homepage .section-meet-sport-style .image-big .magezone-heading > h2 {
    font-size: 31px;
    margin-bottom: 15px;
}
main.main-app-sticky-homepage .section-meet-sport-style .image-big .magezone-heading > h3 {
    font-size: 25px;
    margin: 0 0 24px;
    text-transform: none;
}
main.main-app-sticky-homepage .section-meet-sport-style .image-big .mgz-button > div > div {
    display: flex;
    justify-content: center;
}
main.main-app-sticky-homepage .section-meet-sport-style .image-big .mgz-button button {
    width: 307px;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col {
    padding-left: 30px;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .right-product > .mgz-product-content > .MuiGrid-container {
    width: auto;
    margin: -15px -15px 0 -15px;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .right-product > .mgz-product-content > .MuiGrid-container > .mgz-single-product-card {
    padding: 15px;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .right-product > .mgz-product-content > .MuiGrid-container {
    margin-bottom: 0;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .mgz-single-product-card > .MuiGrid-root:nth-of-type(2) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-top: 10px;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .mgz-single-product-card > .MuiGrid-root > .MuiGrid-item:nth-of-type(1) {
    width: 100%;
    height: 43px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--asics-main);
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .mgz-single-product-card > .MuiGrid-root > .MuiGrid-item h4 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 25px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .mgz-single-product-card > .MuiGrid-root > .MuiGrid-container {
    margin-top: 0;
    margin-bottom: 0px;
}
main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .mgz-single-product-card > .MuiGrid-root > .MuiGrid-container .price_text {
    font-size: 14px;
    line-height: normal;
}

main.main-app-sticky-homepage .section-meet-sport-style .product-list-col .mgz-single-product-card > .MuiGrid-root > .MuiGrid-item:first-child {
    margin-top: 10px;
}

/* Section Shoe Finder */
main.main-app-sticky-homepage .section-shoe-finder > .mgz-column {
    position: relative;
}
main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    padding: 50px 80px;
}
main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) h3 {
    font-size: 50px;
    margin: 0 0 8px;
    text-transform: none !important;
}
main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) h4 {
    font-size: 25px;
    margin: 0 0 30px;
    text-transform: none !important;
}
main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) .mgz-button button {
    width: 148px;
}

@media (max-width: 1184px) {
    body {
        overflow-x: auto; 
    }
    main.main-app-sticky-homepage div#home-banner,
    main.main-app-sticky-homepage div.section-single-banner-full {
        width: auto;
        max-width: none;
        position: static;
        margin-left: auto;
        left: auto;
    }
    main.main-app-sticky-homepage main {
        padding-left: 20px;
        padding-right: 20px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full > .mgz-column > .mgz-element:nth-of-type(2) {
        padding: 20px;
    }
}
@media (max-width: 1023px) {
    body main.main-app-sticky-homepage .section-main-slider > .mgz-column,
    body main.main-app-sticky-homepage main {
        font-size: 0;
    }
    body main.main-app-sticky-homepage main {
        margin-top: 85px !important;
        width: 100%;
    }
    body main.main-app-sticky-homepage .mgz-product-slider .slick-slider .slick-list {
        height: auto !important;
        margin-left: -15px;
        margin-right: -15px;
    }
    body main.main-app-sticky-homepage .mgz-product-slider .slick-slider .slick-list .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text {
        padding: 20px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .top .magezone-html {
        font-size: 30px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .top .magezone-html sup {
        font-size: 15px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .sub .magezone-html {
        font-size: 18px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .top .magezone-html,
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .sub .magezone-html {
        text-align: center;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .mgz-button > div > div {
        justify-content: center;
        display: flex;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text > .mgz-column {
        max-width: 100%;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text .sub {
        justify-content: center;
    }

    main.main-app-sticky-homepage main div.section-shoe-finder .magezon-image picture {
        position: relative;
        overflow: hidden;
    }
    body main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) {
        padding: 20px;
    }
    body main.main-app-sticky-homepage .section-shoe-finder .desc-text {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        padding: 20px;
    }
    body main.main-app-sticky-homepage .section-shoe-finder .desc-text h3,
    body main.main-app-sticky-homepage .section-shoe-finder .desc-text h4 {
        text-align: center !important;
    }
    body main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) h3 {
        font-size: 30px;
    }
    body main.main-app-sticky-homepage .section-shoe-finder > .mgz-column > .mgz-element:nth-of-type(2) h4 {
        font-size: 18px;
    }
    body main.main-app-sticky-homepage .section-shoe-finder .desc-text .mgz-button > div > div {
        justify-content: center;
        display: flex;
    }
}
@media (max-width: 767px) {
    body main.main-app-sticky-homepage {
        margin-bottom: 0 !important;
    }
    main.main-app-sticky-homepage > div {
        padding-bottom: 0;
    }
    body main.main-app-sticky-homepage .section-single-banner-full .desc-text {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
    }
    main.main-app-sticky-homepage main div.section-single-banner-full .magezon-image picture {
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;
    }
    main.main-app-sticky-homepage main div.section-single-banner-full .magezon-image picture img {
        position: absolute;
        height: 100%;
        left: -15%;
        /* left: 50%; */
        top: 0;
        /* transform: translate(-50%, 0); */
        width: auto;
        max-width: none !important;
    }

    body main.main-app-sticky-homepage .section-new-collection .col-img:first-child {
        margin-bottom: 30px;
    }
    body main.main-app-sticky-homepage .section-new-collection .col-img > .mgz-element:nth-of-type(2) {
        position: static;
        padding: 0;
    }

    body main.main-app-sticky-homepage .section-meet-sport-style .product-list-col {
        padding-left: 0;
    }
    body main.main-app-sticky-homepage .section-meet-sport-style .image-big > .mgz-element:nth-of-type(2) {
        position: static;
        padding: 0;
        margin-bottom: 20px;
    }
}